import React from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax'

const RichText = ({ content, className }) => {

    const config = {
        tex: {
            inlineMath: [['$', '$'], ['\\(', '\\)']]
        },
        options: {
            ignoreHtmlClass: 'w-e-text-container',
            processHtmlClass: 'rich-text',
        },
    };

    return (
        <MathJaxContext config={config}>
            <MathJax hideUntilTypeset={'first'}>
                <div className={`rich-text ${className || ''}`} dangerouslySetInnerHTML={{ __html: content }}></div>
            </MathJax>
        </MathJaxContext>
    )
}

export default RichText;